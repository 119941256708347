<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader color="text-center bg-dark text-white">
          <CRow>
            <CCol sm="8" class="d-flex align-items-start ">{{ $t('label.nuevo')+' '+$t('label.port') }} </CCol>
            <CCol sm="4" class="d-flex align-items-center justify-content-end">
              <CButton size="sm" class="close"
                @click="resetForm"
                >
                x
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="mb-3">
            <CRow>
              <CCol sm="1">
              </CCol>
              <CCol sm="4"> 
                <div class="form-group form-row mb-5" rol="group">
                  <div class="col-sm-12 input-group-sm alto">
                    <gmaps-map 
                      :options="mapOptions"
                      @mounted="ready"
                      @click="setMarker"
                    >
                      <gmaps-marker 
                      :options="markerOptions"
                      @move="handleMarkerPosition"
                      />
                    </gmaps-map>
                  </div>
                </div>
              </CCol>
            
              <CCol sm="6">
                <div class="form-group form-row" rol="group">
                  <label class="required text-right col-form-label col-sm-4 col-form-label-sm" for="pais">{{$t('label.country')}}</label>
                  <div class="col-sm-8 input-group-sm">
                    <v-select 
                      :filter="fuseSearchPais"
                      :options="paisOptions"
                      label="CountryName"
                      :placeholder="$t('label.select')"
                      v-model="pais"
                      :disabled="isPaisesEmpty"
                      :clearable="false"
                      :class="isPaisesValid(portVal)"
                    />
                    <div :class="isPaisValid">
                      {{$t('label.required')}}
                    </div>
                  </div>
                </div>
                <CInput                    
                  v-uppercase
                  maxlength="100"
                  :label="$t('label.name')"
                  :placeholder="$t('label.portName')"
                  addLabelClasses="text-right required"
                  v-model.trim="$v.puerto.PortName.$model"
                  :invalid-feedback="errorMessage($v.puerto.PortName)"  
                  :is-valid="hasError($v.puerto.PortName)"
                  :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                  size="sm"
                />
                <div class="position-relative">
                  <div class="form-group form-row" rol="group">
                    <label class="text-right col-form-label col-sm-4 col-form-label-sm" for="pais">{{$t('label.state')}}</label>
                    <div class="col-sm-8 input-group-sm">
                      <v-select
                        v-model="estado"
                        label="StateName"
                        :options="estadoOptions"
                        :filter="fuseSearchEstado"
                        :disabled="isEstadosEmpty"
                        :placeholder="$t('label.select')"
                        :class="isEstadosValid(portVal)"
                      />
                    </div>
                  </div>
                  <loading element="select" v-show="isLoadingEstado"/>
                  <!--
                  <CElementCover 
                    :opacity="0.8"
                    v-show="isLoadingEstado"
                  >
                    <label class="d-inline">{{$t('label.load')}}... </label>
                    <CSpinner size="sm" />
                  </CElementCover>
                  -->
                </div>
                <CInput
                  v-model.trim="$v.puerto.PortCode.$model"
                  v-uppercase
                  :invalid-feedback="errorMessage($v.puerto.PortCode)"
                  :is-valid="hasError($v.puerto.PortCode)"                    
                  :label="$t('label.code')"
                  :placeholder="$t('label.code')"
                  addLabelClasses="text-right required"
                  maxlength="3"
                  :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                  size="sm"
                />
                <div class="form-group form-row" rol="group">
                  <label class="text-right col-form-label col-sm-4 col-form-label-sm" for="pais">
                    {{$t('label.geolocation')}}
                  </label>
                  <div class="col-sm-8 input-group-sm">
                    <input
                      type="text"
                      id="autocomplete"
                      ref="autocomplete"
                      placeholder="Search"
                      class="form-control"
                    />          
                  </div>
                </div>
                <CInput
                  maxlength="15"
                  :label="$t('label.latitude')"
                  addLabelClasses="text-right"
                  :placeholder="placeholderPos.lat"
                  v-model.trim="$v.puerto.Latitude.$model"
                  :is-valid="hasError($v.puerto.Latitude)"
                  :invalid-feedback="errorMessage($v.puerto.Latitude)"
                  :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                  size="sm"
                />
                <CInput
                  maxlength="15"
                  :label="$t('label.longitude')"
                  addLabelClasses="text-right"
                  :placeholder="placeholderPos.lng"
                  v-model.trim="$v.puerto.Longitude.$model"
                  :is-valid="hasError($v.puerto.Longitude)"
                  :invalid-feedback="errorMessage($v.puerto.Longitude)"  
                  :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                  size="sm"
                />
              </CCol>
              <CCol sm="1">
              </CCol>
            </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol sm="12" class="text-right" >
              <CButton
                  color="wipe"
                  class="float-right"
                  :disabled="isSubmit"
                  @click="resetForm"
              >
                  <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
              </CButton>
              <CButton
                  color="add"
                  class="mx-2 float-right"
                  :disabled="isSubmit"
                  @click.stop="submit"
              >
                  <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>         
</div>
</template>

<script>
import PuertoValidations from '@/_validations/puerto/PuertoFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import PuertoMixin from '@/_mixins/puerto';
import ModalMixin from '@/_mixins/modal';

import MapsMixin from './MapsMixin';
import { isLatitude, isLongitude } from '@/_validations/funciones';
import { gmapsMap, gmapsMarker } from 'x5-gmaps';

//Data
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    isLoadingEstado: false,
    pais: null,
    paises: [],
    estado: null,
    estados: [],
    puerto: {
      CountryId: '',
      CountryCode: 'VE',
      StateId: '',
      PortName: '',
      PortCode: '',
      Latitude: '',
      Longitude: ''
    },
    portVal: false,
  }
}

//Methods
function submit() {
  try {
    this.isSubmit = true;
    this.$v.puerto.$touch();

    if (this.$v.puerto.$error) {
      this.isSubmit = false;
      this.portVal = true;
      throw this.$t('label.errorsPleaseCheck');
    }
    let PortJson = this.formatedPuerto();

    this.$http.post('Port-insert', PortJson, { root: 'PortJson' })
    .then(response => {
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function formatedPuerto(){
  if(this.puerto.Latitude == '' && this.puerto.Longitude == ''){
    this.puerto.Latitude = this.placeholderPos.lat!=0?this.placeholderPos.lat:'';
    this.puerto.Longitude = this.placeholderPos.lng!=0?this.placeholderPos.lng:'';
  }

  return {
    CountryId: this.pais.CountryId,
    CountryCode: this.pais.IsoCodeAlpha2,
    StateId: this.estado?this.estado.StateId:'',
    PortName: this.puerto.PortName,
    PortCode: this.puerto.PortCode,
    Latitude: `${this.puerto.Latitude}`,
    Longitude: `${this.puerto.Longitude}`
  }
}
function toggle(newVal) {
  if(newVal){
    this.getPaises()
    this.puerto.Latitude = '';
    this.puerto.Longitude = '';
  }else
    this.resetForm();

  this.$v.$reset();
}
function resetForm() {
  this.puerto.CountryId = '';
  this.puerto.CountryCode = '';
  this.puerto.StateId = '';
  this.puerto.PortName = '';
  this.puerto.PortCode = '';
  this.puerto.Latitude = '';
  this.puerto.Longitude = '';
  this.pais = null;
  this.estado = null;
  this.paises = [];
  this.estados = [];
  this.portVal = false;

  this.placeholderPos.lat = 0;
  this.placeholderPos.lng = 0;
  this.mapOptions = { ...this.mapOptions, center: { lat: this.initLatitud, lng: this.initLongitud } };
  this.markerOptions.position = {  lat: this.initLatitud, lng: this.initLongitud };

  this.$v.$reset();

  this.$emit('child-refresh',true);
}
function isPaisesValid(portVal) {
  if(this.portVal === true){ 
    return (this.pais == null || this.pais == "") ? 'no-valido' : 'valido';
  }
}

function isEstadosValid(portVal) {
  if(this.portVal === true){ 
    return 'valido';
  }
}
//Computeds:
function latitud() {
  return this.puerto.Latitude;
}
function longitud() {
  return this.puerto.Longitude;
}

export default {
  name: 'add-puerto',
  mixins: [PuertoMixin, MapsMixin, ModalMixin,],
  components:{ gmapsMap, gmapsMarker },
  data,
  validations: PuertoValidations,
  directives: UpperCase,
  props: ['value'],
  methods: {
    submit,
    toggle,
    resetForm,
    formatedPuerto,
    isPaisesValid,
    isEstadosValid,
  },
  computed: {
    latitud,
    longitud
  },
  watch: {
    pais: function(newPais) {
      if(newPais){
        this.estado = null;
        this.getEstados(newPais.CountryId);
        this.portVal = true;
        if(newPais.Latitude && newPais.Longitude){
          this.updatePosition(Number.parseFloat(newPais.Latitude), Number.parseFloat(newPais.Longitude));
          this.handleMarkerPosition({
            lat: Number.parseFloat(newPais.Latitude),
            lng: Number.parseFloat(newPais.Longitude)
          });
        }
      }else{
        this.estado = null;
        this.estados = [];
      }
    },
    latitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.puerto.Latitude) && isLongitude(this.puerto.Longitude)){
        this.updatePosition(Number.parseFloat(this.puerto.Latitude), Number.parseFloat(this.puerto.Longitude));
      }
    },
    longitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.puerto.Latitude) && isLongitude(this.puerto.Longitude)){
        this.updatePosition(Number.parseFloat(this.puerto.Latitude), Number.parseFloat(this.puerto.Longitude));
      }
    }
  },
  mounted () {
    this.getPaises();
    if (this.value) {
        //this.setLineaData(this.value);
    }
  },
}
</script>
<style scoped>
.invalid {
  display: block;
}
.alto {
  height: 200px;
}
.form-margin {
  margin-bottom: 0.5rem;
}
.card-body .card-header {
  background-color: #5D6164 !important;
  color: #fff;
}
.card-body .card-header button:hover{
	color: #fff;
	text-emphasis-color: #fff;
	background: transparent;
}

</style>